import React from 'react'

import SEO from '../components/seo'

import hero from '../images/drew/retail/retail-one-page.jpg'

const ImageBlock = () => (
  <div className='grid'>
    <img alt='Copper Compression Information' src={hero} width="100%" style={{margin:'auto', 'display': 'block', maxWidth:'1420px'}} />
  </div>
)

const DrewBreesRetailPage = () => (
  <>
    <SEO title="Drew Brees Join Copper Compression" description="Copper Compression is excited to announce our multi-year partnership with Pro Quarterback, Drew Brees."/>
    <ImageBlock />
  </>
);

export default DrewBreesRetailPage;
